import React from 'react';
import { CheckboxProps } from 'antd';
import * as S from './BaseCheckbox.styles';

export type BaseCheckboxProps = CheckboxProps;

interface BaseCheckboxInterface extends React.FC<BaseCheckboxProps> {
  Group: typeof S.CheckboxGroup;
}

export const BaseCheckbox = React.forwardRef<HTMLInputElement, BaseCheckboxProps>((props, ref) => (
  <S.Checkbox ref={ref} {...props} />
)) as unknown as BaseCheckboxInterface;

BaseCheckbox.Group = S.CheckboxGroup;
