import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { ChangeEvent, useState } from 'react';

type AmazonPayFormProps = {
  setSubmitTrigger: (id: string, couponType: string) => void;
};

export const AmazonPayForm = ({ setSubmitTrigger }: AmazonPayFormProps) => {
  const [amazonID, setAmazonID] = useState<string>('');

  const handleAmazonIDChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAmazonID(event.target.value);
  };

  const handleFormSubmit = () => {
    localStorage.setItem('save-amazon-id', amazonID);
    setSubmitTrigger(amazonID, 'AMAZON');
  };

  return (
    <BaseForm name="couponPaymentForm" onFinish={handleFormSubmit}>
      <BaseForm.Item
        label="Enter Email to send Amazon Coupon"
        name="fieldName"
        rules={[
          {
            message: 'Not a valid Email Id',
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          },
        ]}
      >
        <BaseInput value={amazonID} onChange={handleAmazonIDChange} />
      </BaseForm.Item>

      <BaseForm.Item>
        <BaseButton type="primary" htmlType="submit">
          Send
        </BaseButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
