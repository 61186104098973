export class ApiError<T> extends Error {
  options?: T;

  constructor(message: string, options?: T) {
    super(message);
    this.options = options;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
  toJSON() {
    return {
      message: this.message,
    };
  }
}
