import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from '../../../../api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { createApiToken, deleteApiToken, toggleApiToken } from '@app/api/auth.api';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const BasicTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [copyState, setcopyState] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getBasicTableData(pagination).then((res) => {
        if (isMounted.current) {
          setTableData({ data: res.data, pagination: res.pagination, loading: false });
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = async (id: number) => {
    const resp = await deleteApiToken(id);
    let newData = [...tableData.data];
    newData = newData.filter((item) => item.id !== id);
    setTableData({
      ...tableData,
      data: newData,
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
    notificationController.success({ message: t('tables.deleteMessage', { key: resp.key }) });
  };

  const handleToggleRow = async (id: number) => {
    const resp = await toggleApiToken(id);
    const newData = [...tableData.data];
    newData.forEach((item) => {
      if (item.id === id) {
        item.isActive = !item.isActive;
      }
    });
    setTableData({
      ...tableData,
      data: newData,
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
    notificationController.success({ message: t('tables.updatedMessage', { key: resp.key }) });
  };

  const handleCreateRow = async () => {
    const resp = await createApiToken();
    const newData = [...tableData.data];
    const lastRow = newData[newData.length - 1];
    // Remove the last row and push the new data and last row back
    newData.pop();
    newData.push(resp);
    newData.push(lastRow);

    setTableData({
      ...tableData,
      data: newData,
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
    notificationController.success({ message: t('tables.createMessage', { key: resp.key }) });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'Key',
      dataIndex: 'key',
      render: (text: string) => (
        <>
          <CopyToClipboard text={text} onCopy={() => setcopyState(true)}>
            <span>{text}</span>
          </CopyToClipboard>
        </>
      ),
      filterMode: 'tree',
      filterSearch: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      showSorterTooltip: false,
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { createdAt: Date; key: string; id: number; isActive: boolean }) => {
        return (
          <BaseSpace>
            {record.id === -1 ? (
              <BaseButton
                type="ghost"
                onClick={() => {
                  handleCreateRow();
                }}
              >
                {t('tables.create')}
              </BaseButton>
            ) : (
              <>
                <BaseButton
                  type="ghost"
                  onClick={() => {
                    handleToggleRow(record.id);
                  }}
                >
                  {record.isActive ? t('tables.deactivate') : t('tables.activate')}
                </BaseButton>
                <BaseButton type="default" danger onClick={() => handleDeleteRow(record.id)}>
                  {t('tables.delete')}
                </BaseButton>
              </>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      onChange={handleTableChange}
      bordered
    />
  );
};
