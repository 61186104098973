import { UserModel } from '@app/domain/UserModel';

function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} is a renowned Evangelist with a deep passion for vintage computing devices and cutting-edge technology trends. With a degree in Computer Science from and over a decade of experience in the tech industry, ${user.firstName} has cultivated a vast collection of rare tech artifacts and insights that showcase the evolution of technology over the years.`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  const sampleInvitePrompt = `Compose a personalized LinkedIn connection request message that ${user.firstName} will be sending to {clientFirstName}. The message should highlight ${user.firstName}'s interest in connecting based on {clientFirstName}'s shared passion for technology and potential interest in historical tech artifacts. The invite should be engaging and professional, reflecting ${user.firstName}'s status as a evangelist who values meaningful interactions and knowledge exchange.\n`;

  return sampleInvitePrompt;
}

export const techCollector = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt:
    'Explore my collection of rare tech artifacts and join discussions on the future implications of past technologies.',
  replyPrompt:
    'Thank you for your interest! I would love to hear more about your experiences with technology and any pieces you collect.',
  transformPrompt:
    'Enhance your LinkedIn presence by showcasing unique technological artifacts and their stories, attracting like-minded tech enthusiasts.',
  message: 'Please fill out the below fields to help AI to generate the content...',
};
