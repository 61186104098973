import { UserModel } from '@app/domain/UserModel';

function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} is a dynamic LinkedIn Influencer with a focus on leadership and career development in the tech industry. Holding an MBA from famous Business School, ${user.firstName} has built a substantial following through insightful posts and engaging online discussions. Specializing in empowering young professionals and aspiring leaders by sharing career advice, industry trends, and motivational content, ${user.firstName} collaborates with major tech firms and educational institutions to bring valuable content to the audience. Deep understanding of social media dynamics and the ability to connect with people have made ${user.firstName} a sought-after speaker and consultant in personal branding and digital marketing strategies. Online presence is marked by a strong commitment to authenticity and a passion for helping others achieve their professional goals. Influence extends beyond LinkedIn as ${user.firstName} regularly participates in podcasts, webinars, and live events, making ${user.firstName} a prominent voice in the tech and career coaching communities.`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  const sampleInvitePrompt = `Compose a personalized LinkedIn connection request message that ${user.firstName} will be sending to {clientFirstName}. The message should emphasize ${user.firstName}'s interest in sharing insights on career growth and professional development within the tech industry. It should be engaging, professional, and reflect ${user.firstName}'s status as a thought leader and influencer.\n`;
  return sampleInvitePrompt;
}

export const linkedInInfluencer = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt:
    "Engage with my latest content on effective leadership and innovation in tech. Let's inspire change together!",
  replyPrompt:
    'Thank you for your feedback! It’s great to connect with fellow professionals who are passionate about empowering others.',
  transformPrompt:
    'Revitalize your personal brand by adopting new strategies that highlight your expertise and resonate with your audience.',
  message: 'Please fill out the below fields to help AI to generate the content...',
};
