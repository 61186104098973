import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { deleteToken, deleteUser, readToken } from '@app/services/localStorage.service';

console.log(process.env.REACT_APP_BASE_URL);

// const baseURL = location.origin + '/api';
// console.log(baseURL);
export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 511) {
    deleteToken();
    deleteUser();
    window.location.href = '/';
  }
  throw new ApiError<ApiErrorData>(
    error.response?.data?.message || error.response?.data?.detail || error.message,
    error.response?.data,
  );
});

export interface ApiErrorData {
  message: string;
}
