import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import { Priority } from '@app/constants/enums/priorities';
import { defineColorByPriority } from '@app/utils/utils';
import { Empty, Tooltip } from 'antd';

type BGProps = {
  status: string;
  message: string;
  fixError?: string | React.ReactNode;
};

type BGCheckListProps = {
  bgChecks: BGProps[];
};

export const BGCheckList = ({ bgChecks }: BGCheckListProps) => {
  const customizedEmpty = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span style={{ color: '#ccc', fontSize: '14px' }}>Background check in progress.</span>}
    />
  );

  const customLocale = {
    emptyText: customizedEmpty,
  };

  return (
    <BaseList
      itemLayout="horizontal"
      locale={customLocale}
      dataSource={bgChecks}
      renderItem={(item) => (
        <BaseList.Item style={{ borderBottom: '0px', padding: '4px 0' }}>
          <BaseList.Item.Meta
            avatar={
              <BaseAvatar
                style={{ background: 'transparent' }}
                icon={
                  item.status === 'error' ? (
                    <CloseCircleOutlined style={{ color: defineColorByPriority(Priority.HIGH) }} />
                  ) : (
                    <CheckCircleOutlined style={{ color: defineColorByPriority(Priority.LOW) }} />
                  )
                }
              />
            }
            title={
              item.status === 'error' ? (
                <span>
                  {item.message}
                  <Tooltip title={item.fixError}>
                    <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
                  </Tooltip>
                </span>
              ) : (
                <span>{item.message}</span>
              )
            }
          />
        </BaseList.Item>
      )}
    />
  );
};
