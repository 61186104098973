import { UserModel } from '@app/domain/UserModel';

function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} write your own content here.....`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  const sampleInvitePrompt = `${user.firstName} write your own content here.....`;

  return sampleInvitePrompt;
}

export const customPrompt = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt:
    'Explore my collection of rare tech artifacts and join discussions on the future implications of past technologies.',
  replyPrompt:
    'Thank you for your interest! I would love to hear more about your experiences with technology and any pieces you collect.',
  transformPrompt:
    'Enhance your LinkedIn presence by showcasing unique technological artifacts and their stories, attracting like-minded tech enthusiasts.',
  message: 'Please fill out the below fields to help AI to generate the content...',
};
