import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
// import { References } from '@app/components/common/References/References';
import * as S from './Table.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BasicTable } from '@app/components/common/tables/BasicTable/BasicTable';

const ApiKeysPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const pageId = 'common.snorkell-api-keys';
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  if (!user) return null; // exit if user is not defined
  const desktopLayout = (
    <S.TablesWrapper>
      <S.Card id="basic-table" padding="1.25rem 1.25rem 0">
        <BasicTable />
      </S.Card>
    </S.TablesWrapper>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 20]}>
      {/* <TrendingCollections /> */}
      <BaseCol xs={24}>
        <S.TablesWrapper>
          <S.Card id="basic-table" title={t('tables.basicTable')} padding="1.25rem 1.25rem 0">
            <BasicTable />
          </S.Card>
        </S.TablesWrapper>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>{t(pageId)}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ApiKeysPage;
