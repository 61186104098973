import { BaseBreadcrumb } from '@app/components/common/BaseBreadcrumb/BaseBreadcrumb';
import styled from 'styled-components';

export const ModifiedBreadcrumbs = styled(BaseBreadcrumb)`
  padding: 12px 14px;
  background: var(--background-color);
  border-radius: 8px;

  li:nth-of-type(2) {
    text-transform: capitalize;
  }
`;
