import { UserModel } from '@app/domain/UserModel';

function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} is an accomplished Sales Manager, a leading sales and marketing expert in the tech industry. Graduating with a Master's degree in Marketing, ${user.firstName} has honed a powerful blend of strategic sales skills and a deep understanding of digital marketing trends.`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  const sampleInvitePrompt = `Compose a personalized LinkedIn connection request message that ${user.firstName} will be sending to {clientFirstName}. The message should emphasize ${user.firstName}'s interest in discussing potential solutions that {clientName} might benefit from, particularly regarding a recent project related to Java development.\nHere is the client profile: {clientProfile}\nThe message should be engaging, professional, and highlight how ${user.firstName}'s expertise in sales strategy and tech solutions can add value to {clientName}'s operations.\n`;
  return sampleInvitePrompt;
}

export const sales = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt:
    "Jordan's approach to aligning client needs with our tech solutions ensures sustained growth and customer satisfaction.",
  replyPrompt:
    'Thank you for your interest! Could you specify which areas you are looking to improve with our solutions?',
  transformPrompt:
    'Adapt your sales strategies to focus more on digital transformation and integration of emerging technologies.',
};
