import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseNotification } from '@app/components/common/BaseNotification/BaseNotification';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { ChangeEvent, useState } from 'react';

type UPIFormProps = {
  setSubmitTrigger: (id: string, couponType: string) => void;
};

export const UPIForm = ({ setSubmitTrigger }: UPIFormProps) => {
  const storedUpiID = localStorage.getItem('save-upi-id') || '';
  const [upiID, setUpiID] = useState<string>(storedUpiID);

  const handleUpiIDChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpiID(event.target.value);
  };

  const handleFormSubmit = () => {
    localStorage.setItem('save-upi-id', upiID);
    setSubmitTrigger(upiID, 'UPI');
  };

  return (
    <BaseForm name="couponPaymentForm" onFinish={handleFormSubmit}>
      <BaseForm.Item
        label="Enter UPI Id(For Indian Users)"
        name="fieldName"
        rules={[
          {
            message: 'Not a valid UPI ID',
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+$/,
          },
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <BaseInput value={upiID} onChange={handleUpiIDChange} style={{ flex: 1, marginRight: '8px' }} />
          <BaseButton type="primary" htmlType="submit">
            Submit
          </BaseButton>
        </div>
      </BaseForm.Item>
      <BaseNotification type="info" title="The redeemed amount will be sent to this UPI Id." />
    </BaseForm>
  );
};
