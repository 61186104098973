import { updateEmail } from '@app/api/auth.api';
import { SecurityCodeForm } from '@app/components/auth/SecurityCodeForm/SecurityCodeForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseNotification } from '@app/components/common/BaseNotification/BaseNotification';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { EmailAuthModal } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { emailRegex, isEmailValid } from '@app/utils/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EmailUpdateState = {
  email: string;
  error: string;
};
export const EmailUpdatePopUp = ({ message }: { message: string }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVerifyModalOpen, setVerifyModalOpen] = useState(false);

  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();
  const [emailUpdate, setEmailUpdate] = useState<EmailUpdateState>({
    email: user?.email?.name || '',
    error: '',
  });

  useEffect(() => {
    if (user?.email && isEmailValid(user?.email.name)) {
      setIsModalVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsModalVisible(true);
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [user]);

  const handleEmailSubmit = useCallback(async () => {
    if (!isEmailValid(emailUpdate.email)) {
      notificationController.error({ message: 'Please input a valid email address!' });
      return;
    }
    updateEmail(emailUpdate.email)
      .then((resp) => {
        console.log(resp);
        const { message } = resp.data;
        notificationController.success({
          message,
        });
        setVerifyModalOpen(true);
      })
      .catch((error: { toJSON?: any; message?: string | undefined }) => {
        console.log(error.toJSON());
        notificationController.error({
          message:
            (error as { message?: string })?.message ||
            "We couldn't update your email. Please try again later or contact support",
        });
      });
  }, [emailUpdate.email]);

  const resendVerificationCode = useCallback(async () => {
    if (!isEmailValid(emailUpdate.email)) {
      notificationController.error({ message: 'Please input a valid email address!' });
      return;
    }
    updateEmail(emailUpdate.email)
      .then((resp) => {
        console.log(resp);
        const { message } = resp.data;
        notificationController.success({
          message,
        });
      })
      .catch((error) => {
        notificationController.error({
          message:
            (error as { message?: string })?.message ||
            'Unable to send OTP. Please try again later or contact support@snorkell.ai',
        });
      });
  }, [emailUpdate.email]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <BaseModal
      destroyOnClose
      open={isModalVisible}
      title="Please add your Email"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <BaseButtonsForm isFieldsChanged={false}>
        <BaseButtonsForm.Item
          name="email"
          rules={[
            { required: true, message: t('common.requiredField') },
            { pattern: emailRegex, message: t('common.notValidEmail') },
          ]}
          help={emailUpdate.error}
          validateStatus={emailUpdate.error ? 'error' : ''}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <BaseInput
              value={emailUpdate.email}
              onChange={(e) => setEmailUpdate(() => ({ email: e.target.value, error: '' }))}
            />
            <BaseButton onClick={handleEmailSubmit}>Update</BaseButton>
          </div>
        </BaseButtonsForm.Item>
        <BaseNotification type="warning" title={message} />
      </BaseButtonsForm>

      <EmailAuthModal
        destroyOnClose
        open={isVerifyModalOpen}
        footer={false}
        closable={false}
        onCancel={() => setVerifyModalOpen(false)}
      >
        <SecurityCodeForm
          onBack={() => setVerifyModalOpen(false)}
          onResendCode={resendVerificationCode}
          emailToVerify={emailUpdate.email}
        />
      </EmailAuthModal>
    </BaseModal>
  );
};
