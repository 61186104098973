import { getAllCoupon } from './auth.api';

export interface CouponProps {
  id: number;
  user_id: number;
  coupon_code: string;
  is_used: boolean;
  used_at: string | null;
  amount: number;
  currency: string;
  coupon_type: string;
  coupon_state: string;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface CouponResponse {
  data: CouponProps[];
  pagination: Pagination;
}

export const fetchAllCoupon = (pagination: Pagination): Promise<CouponResponse> => {
  return new Promise(async (resolve) => {
    try {
      const data = await getAllCoupon();
      resolve({
        data,
        pagination: { ...pagination, total: data.length },
      });
    } catch (err) {
      console.error('Error fetching coupons: ', err);
      throw err;
    }
  });
};
