import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useMounted } from '@app/hooks/useMounted';
import { Result } from 'antd';
import { useEffect, useState } from 'react';
import { BGCheckList } from './BGCheckList';
import { UPIForm } from '../couponForm/UPIForm';
import { AmazonPayForm } from '../couponForm/AmazonPayForm';
import { redeemAllCoupon } from '@app/api/auth.api';
import { BaseNotification } from '@app/components/common/BaseNotification/BaseNotification';

type ModalTypeOpenProps = {
  isOpen: boolean;
  isType: string;
};

type CouponModalProps = {
  modalTypeOpen: ModalTypeOpenProps;
  setModalTypeOpen: React.Dispatch<React.SetStateAction<ModalTypeOpenProps>>;
};

type BGProps = {
  status: string;
  message: string | React.ReactNode;
  fixError?: string | React.ReactNode;
};

export const CouponModal = ({ modalTypeOpen, setModalTypeOpen }: CouponModalProps) => {
  const [bgChecks, setBgChecks] = useState<BGProps[]>([]);
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState({
    success: false,
    message: '',
  });
  const { isMounted } = useMounted();
  const fetchRedeemCoupon = async (id: string, couponType: string) => {
    setBgChecks([]);

    try {
      const data = await redeemAllCoupon(id, couponType);
      console.log(data);
      setSubmitTrigger(true);

      const checks = [
        // {
        //   status: data.status.includes('GITHUB_NOT_CONNECTED') ? 'error' : 'success',
        //   message: 'Connect your GitHub account.',
        //   fixError: `Need a quick fix? Just head to your profile page and link your GitHub account. After that, you'll be all set to redeem your coupon!`,
        // },

        {
          status: data.status.includes('GITHUB_APP_NOT_INSTALLED') ? 'error' : 'success',
          message: (
            <span>
              Install the{' '}
              <a href="https://github.com/apps/snorkell-ai" target="_blank" rel="noopener noreferrer">
                ProfilePulse App
              </a>{' '}
              on GitHub to redeem.
            </span>
          ),
          fixError: '',
        },

        // {
        //   status: data.status.includes('INVALID_EMAIL') ? 'error' : 'success',
        //   message: 'Update your email account on the profile page.',
        //   fixError: `Looks like your email needs a quick update. Just visit your profile page, update your email details, and you'll be all set to continue!`,
        // },
      ];

      setBgChecks(checks);

      if (data.status.includes('SUCCESS')) {
        setIsVerified({
          success: true,
          message: "Hooray! You've redeemed your coupon and it will be sent it right to your email inbox in few hours.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (id: string, couponType: string) => {
    if (isMounted.current) {
      fetchRedeemCoupon(id, couponType);
    }
  };

  return (
    <BaseModal
      title={modalTypeOpen.isType === 'upi_payment' ? 'UPI Payment' : 'Amazon Pay Payment'}
      open={modalTypeOpen.isOpen}
      onCancel={() => setModalTypeOpen((prev) => ({ ...prev, isOpen: false }))}
      closable={false}
      footer={null}
    >
      {isVerified.success ? (
        <Result
          status="success"
          title={isVerified.message}
          extra={
            <BaseButton
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
              style={{ margin: 'auto' }}
            >
              Okay
            </BaseButton>
          }
        />
      ) : modalTypeOpen.isType === 'upi_payment' ? (
        <UPIForm setSubmitTrigger={handleSubmit} />
      ) : (
        <AmazonPayForm setSubmitTrigger={handleSubmit} />
      )}
      {submitTrigger && !isVerified.success && <BGCheckList bgChecks={bgChecks} />}
    </BaseModal>
  );
};
