import { DashboardInfo01 } from '@app/components/panel/dashboard-about/dashboard-info-01';
import DashboardWrapper from '../DashboardWrapper';

const DocGenDashboardPage: React.FC = () => {
  return (
    <DashboardWrapper defaultPath="doc-gen-dashboard">
      <DashboardInfo01 />
    </DashboardWrapper>
  );
};

export default DocGenDashboardPage;
