import { UserModel } from '@app/domain/UserModel';

function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} is an adept Tech Recruiter, a staffing agency renowned for specializing in eCommerce, AI, and Web3 recruitment. Holding a Bachelor’s degree, ${user.firstName} brings a unique blend of interpersonal and analytical skills to the role.`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  const sampleInvitePrompt = `Compose a personalized LinkedIn connection request message that ${user.firstName} will be sending to {clientFirstName}. The message should emphasize ${user.firstName}'s interest in sharing insights on career growth and professional development within the tech industry. It should be engaging, professional, and reflect ${user.firstName}'s status as a thought leader and influencer.\n`;
  return sampleInvitePrompt;
}

export const recruiter = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt: "Recruiter's pitch prompt...",
  replyPrompt: "Recruiter's reply prompt...",
  transformPrompt: "Recruiter's transform prompt...",
  message: 'Please fill out the below fields to help AI to generate the content...',
};
