import { UserModel } from '@app/domain/UserModel';
function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} is an innovative Startup Founder and CEO of a startup, a company that pioneers cutting-edge solutions in the AI and machine learning sectors. With a Master’s degree in Entrepreneurship, ${user.firstName} combines deep technical knowledge with strategic business acumen to drive her company's vision forward. Under his leadership, Tech has developed several patented technologies that have revolutionized the way businesses utilize AI for automation and decision-making. ${user.firstName}'s expertise lies in scaling tech startups from concept to market leader, leveraging her skills in investment acquisition, product development, and cross-functional team leadership. ${user.firstName} is deeply committed to fostering a culture of innovation and inclusivity within her team, believing that diverse ideas and perspectives drive true innovation. ${user.firstName} regularly speaks at industry conferences and contributes to discussions on the future of technology and entrepreneurship. Her passion for technology and its impact on the global stage fuels her continuous pursuit of disruptive ideas that challenge the status quo and create new opportunities for growth and impact.`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  const sampleInvitePrompt = `Compose a personalized LinkedIn connection request message that ${user.firstName} will be sending to {clientFirstName}. It should highlight ${user.firstName}'s interest in exploring potential collaborations and investment opportunities.\n The message should be engaging, professional, and convey the innovative spirit of her startup.\n Here are the details on Client Profile: {clientProfile}\n`;
  return sampleInvitePrompt;
}

export const startupFounder = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt:
    'As a founder, my vision is to merge cutting-edge technology with market needs to create sustainable, impactful solutions.',
  replyPrompt:
    'Thank you for reaching out! I’m eager to learn more about your interests and discuss how we can drive innovation together.',
  transformPrompt:
    "Transform your startup's approach by integrating new technologies that not only optimize operations but also enhance user engagement.",
  message:
    "I am a startup founder and CEO of NextGen Tech, a company that pioneers cutting-edge solutions in the AI and machine learning sectors. With a Master’s degree in Entrepreneurship from Stanford University, I combine deep technical knowledge with strategic business acumen to drive my company's vision forward. Under my leadership, NextGen Tech has developed several patented technologies that have revolutionized the way businesses utilize AI for automation and decision-making. My expertise lies in scaling tech startups from concept to market leader, leveraging my skills in investment acquisition, product development, and cross-functional team leadership. I am deeply committed to fostering a culture of innovation and inclusivity within my team, believing that diverse ideas and perspectives drive true innovation. I regularly speak at industry conferences and contribute to discussions on the future of technology and entrepreneurship. My passion for technology and its impact on the global stage fuels my continuous pursuit of disruptive ideas that challenge the status quo and create new opportunities for growth and impact.",
};
