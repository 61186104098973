import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Switch, Input, Button, Typography, notification, Collapse, Select, Tabs } from 'antd';
import {
  CollapseContainer,
  ContentWrapper,
  LayoutContainer,
  TabsContainer,
  UserPersonaSection,
} from './dashboardInfo.styes';
import Tooltip from 'antd/es/tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PersonaContentType, PersonaContents, PersonaEnum } from '../../../schema/personaContent';
import { jobSeeker } from './personaPrompts/jobSeeker';
import { startupFounder } from './personaPrompts/startupFounder';
import { recruiter } from './personaPrompts/recruiter';
import { sales } from './personaPrompts/sales';
import { techCollector } from './personaPrompts/collector';
import { linkedInInfluencer } from './personaPrompts/influencer';
import { customPrompt } from './personaPrompts/custom';
import { PersonaPrompt, addPersonaDetails, fetchPersonaDetails } from '@app/api/personaPrompt.api';
import { ApiErrorData } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import { doUpdateUserProfile } from '@app/store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
const { TextArea } = Input;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

const initialPersonaContentTxt = {
  profileInfo: '', // Customizable by the user
  invitePrompt: '',
  pitchPrompt: '',
  replyPrompt: '',
  transformPrompt: '',
  message: '',
};

const initialPersonaContent: PersonaContents = {
  'Job-Seeker': initialPersonaContentTxt,
  'Startup-Founder': initialPersonaContentTxt,
  Recruiter: initialPersonaContentTxt,
  Sales: initialPersonaContentTxt,
  Collector: initialPersonaContentTxt,
  Influencer: initialPersonaContentTxt,
  // Add more personas as needed
  Custom: initialPersonaContentTxt,
};

const defaultPersonaContent = {
  'Job-Seeker': jobSeeker,
  'Startup-Founder': startupFounder,
  Recruiter: recruiter,
  Sales: sales,
  Collector: techCollector,
  Influencer: linkedInInfluencer,
  // Add more personas as needed
  Custom: customPrompt,
};

type AccordionContentType = {
  title: string;
  content: string;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  placeHolder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  key: string;
  contentType: PersonaContentType;
  isTextChanged: boolean;
};

export const DashboardInfo01 = () => {
  const user = useAppSelector((state) => state.user.user);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingSave, setLoadingSave] = useState(false);
  const [personaContent, setPersonaContent] = useState(initialPersonaContent);

  const selectedPersonaIdx = Object.keys(personaContent).findIndex((persona) => persona === user?.persona.toString());
  console.log('selectedPersonaIdx:', selectedPersonaIdx);

  const [currentPersona, setCurrentPersona] = useState(Object.keys(personaContent)[selectedPersonaIdx]);

  const [isProfileInfoChanged, setProfileInfoChanged] = useState(false);
  const [isAiInviteChanged, setAiInviteChanged] = useState(false);
  const [isAiReplyChanged, setAiReplyChanged] = useState(false);
  const [isAiTransformChanged, setAiTransformChanged] = useState(false);
  const [isAiPitchChanged, setAiPitchChanged] = useState(false);

  const [aiProfileEnabled, setAiProfileEnabled] = useState(true);
  const [aiInviteEnabled, setAiInviteEnabled] = useState(true);
  const [aiPitchEnabled, setAiPitchEnabled] = useState(true);
  const [aiReplyEnabled, setAiReplyEnabled] = useState(true);
  const [aiTransformEnabled, setAiTransformEnabled] = useState(true);

  const [activeKeys, setActiveKeys] = useState(['1', '2', '3', '4']);
  const [aiInvitePrompt, setAiInvitePrompt] = useState("Hi {name},\n\nI hope you're well, invite prompt. <blank>...");
  const [aiProfileInfo, setAiProfileInfo] = useState('Suman Saurabh is an accomplished software engineer...');
  const [aiPitchPrompt, setAiPitchPrompt] = useState("Hi {name},\n\nI hope you're well. <blank>...");
  const [aiReplyPrompt, setAiReplyPrompt] = useState('');
  const [aiTransformPrompt, setAiTransformPrompt] = useState('');
  const [fetchedPersonaContent, setFetchedPersonaContent] = useState<PersonaContents | null>(null);
  const dispatch = useAppDispatch();

  const modifyPersonaPromptContent = (personaDetails: PersonaPrompt[]) => {
    console.log('personaDetails:', personaDetails);
    const modifiedContent: PersonaContents = { ...personaContent };
    const personaMap: Record<string, PersonaPrompt[]> = {};
    personaDetails.forEach((personaDetail) => {
      const { persona, content, content_type } = personaDetail;
      const personaString: string = persona.toString();
      if (personaString in personaMap) {
        personaMap[personaString].push(personaDetail);
      } else {
        personaMap[personaString] = [personaDetail];
      }
    });
    console.log('personaMap:', personaMap);

    Object.keys(personaMap).forEach((persona) => {
      const personaContents = personaMap[persona];
      personaContents.forEach((personaContent) => {
        const { content, content_type } = personaContent;
        console.log('persona1:', persona, 'content1:', content, 'content_type1:', content_type);
        switch (content_type) {
          case PersonaContentType.PROFILE:
            modifiedContent[persona].profileInfo = content;
            break;
          case PersonaContentType.AI_INVITE:
            console.log(
              'personaAI_INVITE:',
              persona,
              'contentAI_INVITE:',
              content,
              'content_typeAI_INVITE:',
              content_type,
            );
            modifiedContent[persona].invitePrompt = content;
            break;
          case PersonaContentType.AI_PITCH:
            modifiedContent[persona].pitchPrompt = content;
            break;
          case PersonaContentType.AI_REPLY:
            modifiedContent[persona].replyPrompt = content;
            break;
          case PersonaContentType.AI_TRANSFORM:
            modifiedContent[persona].transformPrompt = content;
            break;
          default:
            break;
        }
      });
    });

    return modifiedContent;
  };

  useEffect(() => {
    // Call the fetchPersonaDetails API when the component mounts
    console.log('Fetching persona details...');
    fetchPersonaDetails()
      .then((personaDetails) => {
        // Update the fetchedPersonaContent state with the fetched data
        const updatedContent = modifyPersonaPromptContent(personaDetails);
        setPersonaContent(updatedContent);
        setLoading(false);
        console.log('Modified persona contents:', personaDetails);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Failed to fetch persona details:', error);
      });
  }, []);

  useEffect(() => {
    // Update the active keys based on the switch states
    const newActiveKeys = [];
    newActiveKeys.push('1');
    if (aiInviteEnabled) newActiveKeys.push('2');
    if (aiPitchEnabled) newActiveKeys.push('3');
    if (aiReplyEnabled) newActiveKeys.push('4');
    if (aiTransformEnabled) newActiveKeys.push('5');
    setActiveKeys(newActiveKeys);
  }, [aiInviteEnabled, aiPitchEnabled, aiReplyEnabled, aiTransformEnabled]); // Dependencies

  useEffect(() => {
    // Update fields based on the current persona
    const content = personaContent[currentPersona];
    setAiProfileInfo(content.profileInfo);
    setAiInvitePrompt(content.invitePrompt);
    setAiPitchPrompt(content.pitchPrompt);
    setAiReplyPrompt(content.replyPrompt);
    setAiTransformPrompt(content.transformPrompt);
  }, [personaContent, currentPersona]); // Update when currentPersona changes

  const handleSave = async (content: string, contentType: PersonaContentType | undefined, selectedPersona: string) => {
    if (contentType === undefined) {
      console.error('Invalid content type:', contentType);
      notification.error({
        message: 'Error',
        description: 'Invalid content type.',
      });
      return;
    }
    setLoadingSave(true);
    addPersonaDetails(content, selectedPersona, contentType.toString())
      .then(() => {
        setLoadingSave(false);
        notification.success({
          message: 'Settings Saved',
          description: 'Your AI settings have been successfully saved.',
        });
      })
      .catch((error: ApiErrorData) => {
        setLoadingSave(false);
        // eslint-disable-next-line no-console
        console.error('Error:', error);
        notification.error({ message: `Failed:  ${error.message}` });
      });
    console.log('Saving...');
  };

  const accordionContent: AccordionContentType[] = [
    {
      title: 'Profile Info',
      content: aiProfileInfo,
      toggle: aiProfileEnabled,
      setToggle: setAiProfileEnabled,
      placeHolder: 'Enter your profile details. It will help AI to tailor your message based on your profile info.',
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAiProfileInfo(e.target.value);
        setProfileInfoChanged(true); // Set change tracking state to true
      },
      key: '1',
      contentType: PersonaContentType.PROFILE,
      isTextChanged: isProfileInfoChanged,
    },
    {
      title: 'AI Invite',
      content: aiInvitePrompt,
      toggle: aiInviteEnabled,
      setToggle: setAiInviteEnabled,
      placeHolder: 'Enter your invite prompt here. This will guide the AI in creating Linkedin Invite message for you.',
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAiInvitePrompt(e.target.value);
        setAiInviteChanged(true); // Set change tracking state to true
      },
      contentType: PersonaContentType.AI_INVITE,
      isTextChanged: isAiInviteChanged,
      key: '2',
    },
    // {
    //   title: 'AI Pitch',
    //   content: aiPitchPrompt,
    //   toggle: aiPitchEnabled,
    //   setToggle: setAiPitchEnabled,
    //   placeHolder: 'Enter your pitch prompt here. This will guide the AI in creating content for you.',
    //   onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => setAiPitchPrompt(e.target.value),
    //   key: '3',
    // },
    // {
    //   title: 'AI Reply',
    //   content: aiReplyPrompt,
    //   toggle: aiReplyEnabled,
    //   setToggle: setAiReplyEnabled,
    //   placeHolder: 'Enter your reply prompt here. This helps the AI understand how to respond to messages.',
    //   onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => setAiReplyPrompt(e.target.value),
    //   key: '4',
    // },
    // {
    //   title: 'AI Transform',
    //   content: aiTransformPrompt,
    //   toggle: aiTransformEnabled,
    //   setToggle: setAiTransformEnabled,
    //   placeHolder: 'Enter your transform prompt here. This instructs the AI on how to modify existing content.',
    //   onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => setAiTransformPrompt(e.target.value),
    //   key: '5',
    // },
  ];

  const onTabChange = (key: string) => {
    setCurrentPersona(key);
  };

  const setDefaultUserPersona = (key: string) => {
    if (!user) return;
    setLoading(true);
    console.log('key', key);
    console.log('PersonaEnum[key as keyof typeof PersonaEnum]', PersonaEnum[key as keyof typeof PersonaEnum]);
    const selectedPersona: PersonaEnum =
      {
        'Job-Seeker': PersonaEnum.JobSeeker,
        'Startup-Founder': PersonaEnum.StartupFounder,
        Recruiter: PersonaEnum.Recruiter,
        Sales: PersonaEnum.Sales,
        Collector: PersonaEnum.Collector,
        Influencer: PersonaEnum.Influencer,
        Custom: PersonaEnum.Custom,
      }[key] || PersonaEnum.JobSeeker;

    const updatedUser = {
      ...user,
      persona: selectedPersona,
    };

    dispatch(doUpdateUserProfile(updatedUser)).then(() => {
      setLoading(false);
      notificationController.success({
        message: `Successfully updated persona to ${key}. Please fill the details for '${key}' prompt`,
      });
    });
  };

  const setDefaultPrompt = (item: AccordionContentType) => {
    const personaContentTxt = defaultPersonaContent[currentPersona];
    console.log('item:', item, personaContentTxt);
    switch (item.contentType) {
      case PersonaContentType.PROFILE:
        setAiProfileInfo(personaContentTxt.profileInfo(user));
        setProfileInfoChanged(true);
        break;
      case PersonaContentType.AI_INVITE:
        setAiInvitePrompt(personaContentTxt.invitePrompt(user));
        setAiInviteChanged(true);
        break;
      case PersonaContentType.AI_PITCH:
        setAiPitchPrompt(personaContentTxt.pitchPrompt);
        setAiPitchChanged(true);
        break;
      case PersonaContentType.AI_REPLY:
        setAiReplyPrompt(personaContentTxt.replyPrompt);
        setAiReplyChanged(true);
        break;
      case PersonaContentType.AI_TRANSFORM:
        setAiTransformPrompt(personaContentTxt.transformPrompt);
        setAiTransformChanged(true);
        break;
      default:
        break;
    }
  };

  if (!user) return null;

  return (
    <ContentWrapper loading={isLoading}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={3}>Welcome {user.firstName}, to ProfilePulse.ai!</Title>
        <UserPersonaSection>
          <Paragraph style={{ margin: 0, marginRight: '10px' }}>Your selected Persona:</Paragraph>
          <Select defaultValue={currentPersona} style={{ width: 240 }} onChange={setDefaultUserPersona}>
            {Object.keys(personaContent).map((persona) => (
              <Option key={persona} value={persona}>
                {persona}
              </Option>
            ))}
          </Select>
        </UserPersonaSection>
      </div>
      <Paragraph>👋 Hey there! We are super excited to have you on board.</Paragraph>
      <div style={{ marginBottom: '20px' }}>
        ✔ <span>Firstly Install </span>
        <strong>
          <Link to="/redeem-coupons">Chrome Extension</Link>
        </strong>
        <span> to automate LinkedIn messages and connection invites.</span>
      </div>
      <div style={{ marginBottom: '20px' }}>
        ✔{' '}
        <span>Fill the persona prompts that you might find useful, it will help AI to generate messages for you.</span>
      </div>
      <hr></hr>
      <br></br>
      {/* User Persona Dropdown */}

      <LayoutContainer>
        <TabsContainer>
          <Tabs tabPosition="left" defaultActiveKey={currentPersona} onChange={onTabChange}>
            {Object.keys(personaContent).map((persona) => (
              <TabPane tab={persona} key={persona}>
                {/* You can also display summary or brief info about each persona here */}
              </TabPane>
            ))}
          </Tabs>
        </TabsContainer>
        <CollapseContainer>
          <Collapse activeKey={activeKeys}>
            {accordionContent.map((item, index) => (
              // <Panel header={<span>{item.title}</span>} key={item.key}>
              <Panel
                key={index + 1}
                header={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                  >
                    <span>
                      <Tooltip title={`${item.placeHolder}`}>
                        {item.title} <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                    {item.contentType !== PersonaContentType.PROFILE && (
                      <Switch
                        checked={item.toggle}
                        onChange={() => item.setToggle(!item.toggle)}
                        style={{ marginLeft: '10px', marginTop: '0.5rem' }}
                      />
                    )}
                  </div>
                }
              >
                <TextArea
                  placeholder={item.placeHolder}
                  value={item.content}
                  onChange={item.onChange}
                  autoSize={{ minRows: 4, maxRows: 100 }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Button
                    type="dashed"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      setDefaultPrompt(item);
                    }}
                    loading={isLoadingSave}
                  >
                    Set Default Prompt
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => {
                      handleSave(item.content, item.contentType, currentPersona);
                    }}
                    loading={isLoadingSave}
                    disabled={!item.isTextChanged}
                  >
                    Save
                  </Button>
                </div>
              </Panel>
            ))}
          </Collapse>
        </CollapseContainer>
      </LayoutContainer>
    </ContentWrapper>
  );
};
