import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doRefreshToken } from '@app/store/slices/authSlice';
import { Loading } from '@app/components/common/Loading/Loading';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { persistToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';

const RapidLogin: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const message = urlParams.get('message');
  const state = urlParams.get('state');
  const shouldNotClose = urlParams.get('shouldNotClose') === 'true';

  const [, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setLoading(true);
    if (!token) {
      navigate('/auth/login');
    } else {
      persistToken(token);
      dispatch(doRefreshToken())
        .unwrap()
        .then(() => {
          if (!shouldNotClose) {
            window.close();
          }
          if (message) {
            notificationController.success({ message });
          }
          if (state) {
            window.location.href = `${state}?token=${token}`;
          }
          navigate('/');
          setLoading(false);
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
          navigate('/auth/login');
        });
    }
  }, [token, navigate, dispatch, message, shouldNotClose]);
  return (
    <>
      <h1 color={BASE_COLORS.green}>Loading....</h1>
      <Loading size="10000" color={BASE_COLORS.green}></Loading>
    </>
  );
};

export default RapidLogin;
