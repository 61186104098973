import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const { t } = useTranslation();
  interface KeyCountResult {
    totalKeys: number;
    emptyKeys: number;
  }

  function countKeys(obj: Record<string, unknown>): KeyCountResult {
    let totalKeys = 0;
    let emptyKeys = 0;

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        totalKeys++;

        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          const nestedCount = countKeys(value as Record<string, unknown>);
          totalKeys += nestedCount.totalKeys;
          emptyKeys += nestedCount.emptyKeys;
        } else if (value === '' || value === null || value === undefined) {
          emptyKeys++;
        }
      }
    }

    return {
      totalKeys,
      emptyKeys,
    };
  }
  const { totalKeys, emptyKeys } = countKeys({ ...profileData });
  let profilePercentage = (emptyKeys / totalKeys) * 100;
  // console.log({ profilePercentage });
  profilePercentage = Math.ceil(profilePercentage / 5) * 5;
  const [fullness] = useState(profilePercentage);
  let imgUrl = '';
  if (profileData) {
    imgUrl = profileData?.imgUrl
      ? profileData.imgUrl
      : `https://ui-avatars.com/api/?name=${profileData.firstName}+${profileData.lastName}`;
  }
  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={imgUrl} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
      <S.Subtitle>{profileData?.userName}</S.Subtitle>
      <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
      <S.Text>{t('profile.fullness')}</S.Text>
    </S.Wrapper>
  ) : null;
};
