export const handleOauthSubmit = (params: Record<string, string>, providerLoginUrl: string, isDesktop = true) => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = new URLSearchParams(params);
  const CLIENT_ORIGIN = process.env.REACT_APP_BASE_URL;

  console.log('CLIENT_ORIGIN', CLIENT_ORIGIN);
  let loginUrl = `${CLIENT_ORIGIN}${providerLoginUrl}`;
  if (searchParams) {
    loginUrl = `${loginUrl}?${searchParams}`;
  }

  if (queryParams) {
    loginUrl = `${loginUrl}&${queryParams}`;
  }

  console.log('loginUrl', loginUrl);
  if (!isDesktop) {
    window.location.href = loginUrl;
    return;
  }

  const popupWinWidth = 600;
  const popupWinHeight = 600;
  const left = (screen.width - popupWinWidth) / 2;
  const top = (screen.height - popupWinHeight) / 4;
  const popup = window.open(
    loginUrl,
    'popup',
    'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left,
  );
  if (!popup || popup.closed || typeof popup.closed == 'undefined') {
    //POPUP BLOCKED
    window.location.href = loginUrl;
  }
  const checkPopup = setInterval(() => {
    if (!popup || popup.closed || typeof popup.closed == 'undefined') {
      clearInterval(checkPopup);
      window.location.href = '/';
    }
  }, 500);
};

export const GITHUB_OAUTH_URL = '/oauth/github/login';
export const GOOGLE_OAUTH_URL = '/oauth/google/login';
export const GITHUB_CONNECTOR_URL = '/api/oauth/github/connect'; // this will go as header redirect
export const GOOGLE_CONNECTOR_URL = '/api/oauth/google/connect'; // this will go as header redirect
