import { httpApi } from '@app/api/http.api';
import { PersonaContentType, PersonaEnum } from '@app/schema/personaContent';

export type PersonaPrompt = {
  id: number | null;
  user_id: number | null;
  content: string;
  persona: PersonaEnum;
  content_type: PersonaContentType;
};

export const fetchPersonaDetails = (): Promise<PersonaPrompt[]> => {
  const url = `/v1/persona/get`;
  return httpApi.get<PersonaPrompt[]>(url).then(({ data }) => data);
};

export const addPersonaDetails = (content: string, persona: string, contentType: string): Promise<PersonaPrompt> => {
  const url = `/v1/persona/add`;
  const config = JSON.stringify({
    content,
    persona,
    content_type: contentType,
  });
  const headers = { 'Content-Type': 'application/json' };

  return httpApi.post<PersonaPrompt>(url, config, { headers }).then(({ data }) => data);
};

export const updateUserPersona = (userId: number, persona: PersonaEnum): Promise<PersonaPrompt> => {
  const url = `/v1/user/${userId}/set/persona?persona=${persona}`;
  const headers = { 'Content-Type': 'application/json' };
  return httpApi.get<PersonaPrompt>(url, { headers }).then(({ data }) => {
    window.location.reload();
    return data;
  });
};
