import { DownOutlined } from '@ant-design/icons';
import { CouponProps, CouponResponse, Pagination, fetchAllCoupon } from '@app/api/coupon.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { useMounted } from '@app/hooks/useMounted';
import { MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import { CouponModal } from '../couponModal/CouponModal';

export const CouponTable: React.FC = () => {
  const [coupon, setCoupon] = useState<CouponProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({ current: 1, pageSize: 5, total: 0 });
  const [modalTypeOpen, setModalTypeOpen] = useState({
    isOpen: false,
    isType: '',
  });

  const [couponAmount, setCouponAmount] = useState({
    totalAmount: 0,
    currency: '',
    totalPending: 0,
    earned: 0,
  });

  const { isMounted } = useMounted();

  const handleFetchCoupons = useCallback(
    async (pagination: Pagination) => {
      setLoading(true);
      try {
        const response: CouponResponse = await fetchAllCoupon(pagination);
        console.log(response);

        if (isMounted.current) {
          setCoupon(response.data);
          setPagination(response.pagination);
          setCouponAmount(() => ({
            totalAmount: response.data.reduce((acc, item) => (item.is_used ? acc : acc + item.amount), 0),
            totalPending: response.data.reduce((acc, item) => (!item.is_used ? acc : acc + item.amount), 0),
            earned: response.data.reduce(
              (acc, item) => (item.coupon_state == 'PAYMENT_COMPLETE' ? acc + item.amount : acc),
              0,
            ),
            currency: (response?.data && response?.data.length > 0 && response?.data[0].currency) || 'USD',
          }));
        }
      } catch (err) {
        console.error('Failed to fetch coupons: ', err);
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
        console.log(loading);
      }
    },
    [isMounted],
  );

  useEffect(() => {
    handleFetchCoupons({ current: 1, pageSize: 5, total: 0 });
  }, [handleFetchCoupons]);

  const handleCouponTableChange = (pagination: Pagination) => {
    handleFetchCoupons(pagination);
  };

  const dropdownItems: MenuProps['items'] = [
    {
      key: 'upi_payment',
      label: 'UPI Payment',
    },
    {
      key: 'amazon_pay',
      label: 'Amazon Pay',
    },
  ];

  const handleRedeem: MenuProps['onClick'] = ({ key }) => {
    setModalTypeOpen({ isOpen: true, isType: key });
  };

  const columns: ColumnsType<CouponProps> = [
    {
      title: 'Coupon Code',
      dataIndex: 'coupon_code',
      key: 'coupon_code',
    },
    {
      title: 'Type of Coupon',
      dataIndex: 'coupon_type',
      key: 'coupon_type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number, record: CouponProps) => `${amount} ${record.currency}`,
    },
    {
      title: 'Usage Status',
      dataIndex: 'is_used',
      key: 'is_used',
      render: (is_used: boolean, record: CouponProps) => (is_used ? `Used on ${record.used_at}` : <span>Unused</span>),
    },
    {
      title: 'Payment Status',
      dataIndex: 'coupon_state',
      key: 'coupon_state',
      render: (_, record: CouponProps) => {
        switch (record.coupon_state) {
          case 'PAYMENT_ACTIVE':
            return <span>Active</span>;
          case 'PAYMENT_PENDING':
            return <span>Pending</span>;
          case 'PAYMENT_COMPLETE':
            return <span>Completed</span>;
        }
      },
    },

    /*        
        {
            title: 'Redeem Coupon',
            dataIndex: 'redeem_coupon',
            key: 'redeem_coupon',
            render: (_, record: CouponProps) =>
            record.is_used ? <span>-</span> : <BaseButton onClick={() => handleRedeem}>Redeem</BaseButton>,
        },
    */
  ];

  return (
    <>
      <BaseSpace direction="vertical" style={{ width: '100%' }}>
        <BaseSpace direction="horizontal" style={{ width: '100%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <BaseButton type="text" size="small" loading={loading} style={{ cursor: 'text' }}>
            To Redeem:
            {loading ? (
              ' Calculating'
            ) : (
              <>
                <span>{couponAmount.totalAmount + ' '}</span>
                <span>{couponAmount.currency}</span>
              </>
            )}
            || Pending Amount:
            {loading ? (
              ' Calculating'
            ) : (
              <>
                <span>{couponAmount.totalPending + ' '}</span>
                <span>{couponAmount.currency}</span>
              </>
            )}
            || Earned:
            {loading ? (
              ' Calculating'
            ) : (
              <>
                <span>{couponAmount.earned + ' '}</span>
                <span>{couponAmount.currency}</span>
              </>
            )}
          </BaseButton>

          <BaseDropdown menu={{ items: dropdownItems, onClick: handleRedeem }} trigger={['click']}>
            <BaseButton
              type="primary"
              onClick={(e) => e.preventDefault()}
              size="middle"
              style={{ marginLeft: 'auto', marginBottom: '12px' }}
            >
              Redeem Coupon <DownOutlined />
            </BaseButton>
          </BaseDropdown>
        </BaseSpace>

        <BaseTable
          columns={columns}
          dataSource={coupon}
          loading={loading}
          pagination={pagination}
          onChange={handleCouponTableChange}
          scroll={{ x: 800 }}
          bordered
        />
      </BaseSpace>

      <CouponModal modalTypeOpen={modalTypeOpen} setModalTypeOpen={setModalTypeOpen} />
    </>
  );
};
