import { httpApi } from '@app/api/http.api';
import { Priority } from '../constants/enums/priorities';
import { getAllApiToken } from './auth.api';

export interface Tag {
  value: string;
  priority: Priority;
}

export interface BasicTableRow {
  id: number;
  key: string;
  isActive: boolean;
  createdAt: Date;
  // age: number;
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface BasicTableData {
  data: BasicTableRow[];
  pagination: Pagination;
}

export interface TreeTableRow extends BasicTableRow {
  children?: TreeTableRow[];
}

export interface TreeTableData extends BasicTableData {
  data: TreeTableRow[];
}

export interface EditableTableData extends BasicTableData {
  data: BasicTableRow[];
}

export const getBasicTableData = (pagination: Pagination): Promise<BasicTableData> => {
  const noPreviousData = {
    id: -1,
    key: "No API key found, please click an 'Create' button to generate one.",
    createdAt: new Date(),
    isActive: false,
    // age: 32,
  };
  const lastEntry = {
    id: -1,
    key: "Please click an 'Create' button to generate one.",
    createdAt: new Date(),
    isActive: false,
    // age: 32,
  };
  return new Promise(async (res, rej) => {
    try {
      const data = await getAllApiToken();
      if (data.length === 0) {
        data.push(noPreviousData);
      } else {
        data.push(lastEntry);
      }
      res({
        data: data,
        pagination: { ...pagination, total: data.length },
      });
    } catch (error) {
      console.error(error);
      rej(error);
    }
  });
};
