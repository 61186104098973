import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/inputs/VerificationCodeInput/VerificationCodeInput';
import { notificationController } from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { verifyEmail } from '@app/api/auth.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
  onVerificationSuccess?: () => void;
  onResendCode?: () => void;
  emailToVerify?: string;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({
  onBack,
  onFinish,
  onVerificationSuccess,
  onResendCode,
  emailToVerify,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [securityCode, setSecurityCode] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleVerifyCode = async () => {
    if (securityCode.length === 6 && emailToVerify) {
      try {
        setLoading(true);
        const response = await verifyEmail(emailToVerify, securityCode);
        const { success, token, message } = response.data;

        console.log(response.data);

        if (success === 'true') {
          onVerificationSuccess?.();
          onFinish?.();
          navigate(`/auth/rapid/login?token=${token}`);
          notificationController.success({ message });
        }
      } catch (err) {
        console.error(err);
        notificationController.error({ message: 'The entered code is incorrect. Please check and try again.' });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    handleVerifyCode();
  }, [securityCode, emailToVerify]);

  const handleBackClick = () => {
    onBack ? onBack() : navigate(-1);
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={handleBackClick}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>

        <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>

          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>

          {isLoading ? <BaseSpin /> : <VerificationCodeInput autoFocus onChange={setSecurityCode} />}

          <BaseButton type="link" onClick={() => onResendCode?.()}>
            <S.NoCodeText>Didn&apos;t get a verification code?</S.NoCodeText>
          </BaseButton>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
