import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { EmailUpdatePopUp } from './EmailUpdatePopUp';
import * as S from './DashboardWrapper.styles';

type DashboardWrapperTypes = {
  defaultPath?: string;
  children: React.ReactNode;
};

const DashboardWrapper: React.FC<DashboardWrapperTypes> = ({ children, defaultPath }) => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user.user);

  if (!user) return null;

  const pageId = 'common.snorkell-dashboard';

  const desktopLayout = (
    <BaseRow gutter={[30, 30]}>
      <BaseCol xs={24}>{children}</BaseCol>
    </BaseRow>
  );

  const mobileLayout = (
    <BaseRow gutter={[20, 20]}>
      <BaseCol xs={24}>{children}</BaseCol>
    </BaseRow>
  );

  const message = 'Register your Email for Password Resets or Account Recovery';

  return (
    <>
      <PageTitle>{t(pageId)}</PageTitle>
      {isDesktop ? desktopLayout : mobileLayout}

      {defaultPath === 'doc-gen-dashboard' ? <EmailUpdatePopUp message={message} /> : ''}
    </>
  );
};

export default DashboardWrapper;
