import { UserModel } from '@app/domain/UserModel';

function getProfileInfo(user: UserModel) {
  const sampleProfile = `${user.firstName} is an accomplished software engineer with a diverse background in technology and leadership roles. With over 10 years of experience, ${user.firstName} has demonstrated a strong aptitude for developing innovative software solutions and leading technology teams to success. His expertise spans a wide range of programming languages, databases, and frameworks, including Typescript, Python, React, MongoDB, and Kubernetes, among others.`;
  return sampleProfile;
}

function getInvitePrompt(user: UserModel) {
  return `Compose a personalized connection request message for linkedin that ${user.firstName} will be sending to {clientFirstName}. It should highlight that ${user.firstName} is interested in Software Roles.\n The message should be engaging and professional. Here are the details on ${user.firstName}'s profile - {userProfile}.\n`;
}

export const jobSeeker = {
  profileInfo: getProfileInfo,
  invitePrompt: getInvitePrompt,
  pitchPrompt: "Job-Seeker's pitch prompt...",
  replyPrompt: "Job-Seeker's reply prompt...",
  transformPrompt: "Job-Seeker's transform prompt...",
  message: 'Please fill out the below fields to help AI to generate the content...',
};
