import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
// import axios from 'axios';
// import { AxiosRequestConfig } from 'axios';
import * as qs from 'qs';
import { BasicTableRow } from './table.api';
import { CouponProps } from './coupon.api';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const transformSignUpRequestForBackend = (signUpData: SignUpRequest) => {
  return {
    ...signUpData,
    first_name: signUpData.firstName,
    last_name: signUpData.lastName,
    username: signUpData.email,
  };
};

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
  email: string;
}

export interface NewPasswordData {
  newPassword: string;
  email: string;
  code: string;
}

export interface NewPasswordDataWithOldPassword {
  newPassword: string;
  password: string;
  email: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
  tokenType: string;
}

export interface SignUpResponse {
  token: string;
  token_type: string;
  user: UserModel;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  console.log(loginPayload, 'loginPayload');
  return httpApi
    .post<LoginResponse>('/login', qs.stringify({ ...loginPayload, username: loginPayload.email }))
    .then(({ data }) => data);
};

/**
 * Makes a request to the '/google/login' endpoint to log in using Google.
 *
 * @param loginPayload The login request payload.
 * @returns A promise that resolves to the login response.
 * @throws {Error} If there is an error making the request.
 *
 * @example
 * const payload = {
 *   // login request payload properties
 * };
 *
 * try {
 *   const response = await loginGoogle(payload);
 *   console.log(response);
 * } catch (error) {
 *   console.error(error);
 * }
 */
export const loginGoogle = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  console.log(loginPayload, 'loginPayload');
  return httpApi.get<LoginResponse>('/google/login').then(({ data }) => {
    console.log(data, 'data');
    return data;
  });
};

export const loginGithub = (urlParam: URLSearchParams): Promise<LoginResponse> => {
  const url = `/github/callback?${urlParam}`;
  return httpApi.get<LoginResponse>(url).then(({ data }) => {
    return data;
  });
};

export const refreshToken = (): Promise<LoginResponse> => {
  const url = '/refreshToken';
  return httpApi.get<LoginResponse>(url).then(({ data }) => {
    return data;
  });
};

export const signUp = async (signUpData: SignUpRequest): Promise<SignUpResponse> => {
  const transformSignUpRequest = transformSignUpRequestForBackend(signUpData);
  return await httpApi.post<SignUpResponse>('/signup', { ...transformSignUpRequest }).then(({ data }) => data);
};

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const setNewPasswordFromOldPassword = (newPasswordData: NewPasswordDataWithOldPassword): Promise<undefined> =>
  httpApi.post<undefined>('setNewPasswordFromOldPassword', { ...newPasswordData }).then(({ data }) => data);

export const getAllApiToken = (): Promise<BasicTableRow[]> =>
  httpApi.get<BasicTableRow[]>('/v1/apiToken/get/all').then(({ data }) => data);

export const createApiToken = (): Promise<BasicTableRow> =>
  httpApi.get<BasicTableRow>('/v1/apiToken/create').then(({ data }) => data);

export const deleteApiToken = (id: number): Promise<Record<string, string>> =>
  httpApi.get<Record<string, string>>(`/v1/apiToken/${id}/delete`).then(({ data }) => data);

export const toggleApiToken = (id: number): Promise<Record<string, string>> =>
  httpApi.get<Record<string, string>>(`/v1/apiToken/${id}/toggle`).then(({ data }) => data);

export const getAllCoupon = (): Promise<CouponProps[]> =>
  httpApi.get<CouponProps[]>('/v1/coupon/get/all').then(({ data }) => data);

export const redeemAllCoupon = async (id: string, couponType: string) => {
  const config = JSON.stringify({
    redeem_type: couponType,
    redeem_id: id,
  });

  const { data } = await httpApi.post('/v1/coupon/redeem', config, {
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
};

export const updateEmail = async (email: string) => {
  const config = JSON.stringify({
    email,
  });

  const data = await httpApi.post('/v1/user/email/update/request', config, {
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
};

export const verifyEmail = async (email: string, securityCode: string) => {
  const config = JSON.stringify({
    email: email,
    code: securityCode,
  });

  const data = await httpApi.post('/v1/user/email/update/verify', config, {
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
};

export const getReferalID = async (id?: number) =>
  httpApi.get(`/v1/user/referred?user_id=${id}`).then(({ data }) => data);
