import styled, { keyframes } from 'styled-components';
import { media } from '@app/styles/themes/constants'; // Assuming you have this file

const theme = {
  primaryColor: '#52c41a',
  textColor: '#000',
};

// export const ContentWrapper = styled.div`
//   padding: 20px;
//   max-width: 800px;
//   margin: auto;

//   .ant-collapse > .ant-collapse-item > .ant-collapse-header {
//     background-color: #f0f2f5;
//     border-radius: 4px;
//     margin-bottom: 8px;
//   }

//   .ant-collapse-content > .ant-collapse-content-box {
//     background-color: #fff;
//     padding: 16px;
//     border-radius: 4px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   }
// `;

export const UserPersonaSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  .ant-typography {
    margin: 0;
    margin-right: 10px;
    color: ${theme.textColor};

    @media only screen and ${media.md} {
      font-size: 0.875rem;
    }
  }

  .ant-select {
    width: 240px;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s infinite linear;
`;

interface ContentWrapperProps {
  loading: boolean;
}

export const ContentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['loading'].includes(prop) && defaultValidatorFn(prop),
})<ContentWrapperProps>`
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  position: relative;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
  transition: opacity 0.3s ease;

  &:after {
    content: '${(props) => (props.loading ? '<Spinner />' : '')}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(props) => (props.loading ? 'block' : 'none')};
  }
`;

export const LayoutContainer = styled.div`
  display: flex;
  align-items: flex-start; /* Align the tabs and the content top */
`;

export const TabsContainer = styled.div`
  flex: 0 0 200px; /* adjust width as needed */
  margin-right: 20px; /* Space between tabs and collapse */
`;

export const CollapseContainer = styled.div`
  flex: 1; /* Take up the remaining space */
`;
