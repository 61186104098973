import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface PageState {
  route: string;
  pageId: string;
}

const pageDetailsStr = localStorage.getItem('pageState');
const pageState: PageState = pageDetailsStr ? (JSON.parse(pageDetailsStr) as PageState) : { route: '', pageId: '' };

const initialState: PageState = pageState;

export const setPageState = createAction<PrepareAction<PageState>>('pageState/setPageState', (pageState) => {
  localStorage.setItem('pageState', JSON.stringify(pageState));

  return {
    payload: pageState,
  };
});

export const pageStateSlice = createSlice({
  name: 'pageState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setPageState, (state, action) => {
      state.route = action.payload.route;
      state.pageId = action.payload.pageId;
    });
  },
});

export default pageStateSlice.reducer;
