import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doGithubLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Loading } from '@app/components/common/Loading/Loading';
import { BASE_COLORS } from '@app/styles/themes/constants';

const GithubOauth: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!code) {
      navigate('/');
    } else {
      dispatch(doGithubLogin(urlParams))
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setTimeout(() => {
            navigate('/auth/login');
          }, 1000);
        });
    }
  });
  return (
    <>
      <h1 color={BASE_COLORS.green}>Loading....</h1>
      <Loading size="10000" color={BASE_COLORS.green}></Loading>
    </>
  );
};

export default GithubOauth;
