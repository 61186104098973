import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ModifiedBreadcrumbs } from './MainNavs.styles';
import { HomeOutlined } from '@ant-design/icons';
import { useMemo } from 'react';

const mainNavMap: Record<string, string> = {
  dashboard: 'Dashboard',
  'coding-assistant-dashboard': 'coding assistant dashboard',
  'snorkell-api-keys': 'API keys',
  profile: 'profile',
  'profile/personal-info': 'account',
  'profile/security-settings': 'security settings',
  'profile/payments': 'payments',
  'redeem-coupons': 'coupons',
};

export const MainNavs = () => {
  const location = useLocation();

  const breadcrumbItems = useMemo(() => {
    const pathSnippets = location.pathname.split('/').filter((index) => index);

    return [
      <Breadcrumb.Item key="home">
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>,

      ...pathSnippets.map((snippet, index, arr) => {
        const path = `${arr.slice(0, index + 1).join('/')}`;
        let breadcrumbName = mainNavMap[path];

        // handle dynamic paths
        if (path.includes('/dashboard') && !breadcrumbName) {
          breadcrumbName = snippet;
        }

        if (index === pathSnippets.length - 1) {
          return <Breadcrumb.Item key={index}>{breadcrumbName || snippet}</Breadcrumb.Item>;
        }

        return (
          <Breadcrumb.Item key={index}>
            <Link to={path}>{breadcrumbName || snippet}</Link>
          </Breadcrumb.Item>
        );
      }),
    ];
  }, [location]);

  return <ModifiedBreadcrumbs>{breadcrumbItems}</ModifiedBreadcrumbs>;
};
