export interface PersonaContent {
  profileInfo: string;
  invitePrompt: string;
  pitchPrompt: string;
  replyPrompt: string;
  transformPrompt: string;
  message: string;
}

export enum PersonaEnum {
  JobSeeker = 'Job-Seeker',
  StartupFounder = 'Startup-Founder',
  Recruiter = 'Recruiter',
  Sales = 'Sales',
  Collector = 'Collector',
  Influencer = 'Influencer',
  Custom = 'Custom',
}

export enum PersonaContentType {
  AI_INVITE = 'AI_INVITE',
  AI_PITCH = 'AI_PITCH',
  AI_TRANSFORM = 'AI_TRANSFORM',
  AI_REPLY = 'AI_REPLY',
  PROFILE = 'PROFILE',
}

export interface PersonaContents {
  [key: string]: PersonaContent;
}
