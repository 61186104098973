import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setPageState } from '@app/store/slices/pageStateSlice';
import { WithChildrenProps } from '@app/types/generalTypes';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  const route = useLocation().pathname;
  const dispatch = useAppDispatch();
  useEffect(() => {
    const pageState = { route, pageId: children };
    dispatch(setPageState(pageState));
  });

  return (
    <Helmet>
      <title>{children} | ProfilePulse</title>
    </Helmet>
  );
};
