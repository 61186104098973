import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';

export const updateUserProfile = (userPayload: UserModel): Promise<UserModel> => {
  console.log(userPayload, 'userPayload');
  console.log(userPayload.id, 'userPayload-id');
  const response = httpApi.put<UserModel>(`/v1/users`, { ...userPayload }).then(({ data }) => {
    console.log(response, 'response');
    return data;
  });
  // console.log
  return response;
};

export const reloadUserProfile = (): Promise<UserModel> => httpApi.get<UserModel>('v1/user').then(({ data }) => data);
