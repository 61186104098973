import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useResponsive } from '@app/hooks/useResponsive';
import { useMemo } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { CouponTable } from './couponTable/CouponTable';
import styled from 'styled-components';
import { EmailUpdatePopUp } from '../DashboardPages/EmailUpdatePopUp';
import { isEmailValid } from '@app/utils/utils';

const WarningMessage = styled.span`
  color: orange;
  /* Add other styles as needed */
`;

const CouponsPage: React.FC = () => {
  const { isTablet } = useResponsive();
  const user = useAppSelector((state) => state.user.user);

  const content = useMemo(
    () => (
      <BaseRow gutter={[0, 20]}>
        <BaseCol span={24}>
          <>
            {!(user?.email && isEmailValid(user?.email.name)) ? (
              <WarningMessage>
                * <a onClick={() => (window.location.href = '/redeem-coupons')}>Click here</a> to add your Email and
                redeem coupons.
              </WarningMessage>
            ) : null}
          </>
          <CouponTable />
        </BaseCol>
      </BaseRow>
    ),
    [],
  );
  const message = 'Please add your Email to redeem coupons';
  if (!user) return null; // exit if user is not defined
  return (
    <>
      <PageTitle>Coupons</PageTitle>
      {isTablet ? <BaseCard>{content}</BaseCard> : content}
      <EmailUpdatePopUp message={message} />
    </>
  );
};

export default CouponsPage;
